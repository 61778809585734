import { useToast } from 'vue-toastification'
import type { ShippingWay, Store } from './useMenuModel'
export const useContactViaWhatsAppForMaterials = (
  material?: Material,
  offer?: GroupOffer
) => {
  const { url: urlLocal } = useDomainHost()
  const { chechIfStoreIsOpennning } = useBill()
  const toast = useToast()
  const { t } = useNuxtApp().$i18n
  const store = material?.store || offer?.store
  const isStoreOpening = store ? chechIfStoreIsOpennning(store) : true
  if (isStoreOpening) {
    const whatsapp = useWhatsappNumer(false, store)
    if (whatsapp) {
      let output = ''

      output += encodeURIComponent(`${t('hello')}: `) + '%0A'
      if (material) {
        output +=
          encodeURIComponent(
            `${t('I_would_like_to_contact')} ${t('has_the_link', {
              name: material.name
            })} `
          ) + '%0A'
        output += `${urlLocal + '/materials/' + material.id}` + '%0A'
      }
      if (offer) {
        output +=
          encodeURIComponent(
            `${t('I_would_like_to_contact')} ${t('offer_containing', {
              number: offer.offer_number
            })} `
          ) + '%0A'
        offer.materials?.forEach((material: Material, index: number) => {
          output +=
            encodeURIComponent(
              `${index + 1} - ${t('has_the_link', { name: material.name })} `
            ) + '%0A'
          output += `${urlLocal + '/materials/' + material.id}` + '%0A'
        })
      }

      window.open(
        'https://api.whatsapp.com/send?phone=' + whatsapp + '&text=' + output,
        '_blank'
      )
    } else {
      toast.error(t('store_does_not_have_phone'), { timeout: 1000 })
    }
  }
}
function getMaterialNameWithPrice(
  priceVisible: number | true,
  item: CartItem,
  itemPrice: number,
  currencyCode: any
) {
  let materialNameWithPrice = `${item.material.name}:         `
  if (item.quantityByUnit) {
    materialNameWithPrice += item.quantityByUnit
    if (priceVisible) {
      materialNameWithPrice += `  × ${useStringPrice(
        itemPrice * item.quantity,
        currencyCode
      )}`
    }
  } else {
    materialNameWithPrice += `${item.quantity} ${item.material.unit || ''}`
    if (priceVisible) {
      materialNameWithPrice += `  × ${useStringPrice(itemPrice, currencyCode)}`
    }
  }

  return materialNameWithPrice
}
export const useSentItemsCartByWhatsApp = (
  itemsWithOutOffers: CartItem[],
  itemsWithOffers: object,
  store: Store,
  totalPrice: number,
  notes: string,
  countCostItems: {
    originalPrice: number
    discount: number
  },
  selectedPayWays: {
    payType?: string
    payWayCard?: payWay
  },
  delivery: ShippingWay | undefined,
  tablePriceAdde: number,
  table:
    | {
        store_id?: string
        table_id?: string
        table_number?: string
      }
    | undefined,
  currency: {
    code: any
    currencyIsRtl: any
  },

  openDialog: any
) => {
  const isStoreHasPayWays =
    (store?.pay_on_door ||
      (store?.pay_by_transfer && store?.pay_ways?.length)) &&
    !table?.table_id
  const priceVisible = store.price_visible ?? true
  const allOffersWithItems = Object.values(itemsWithOffers)
  const { url: urlLocal } = useDomainHost()
  const quantityItemsPerStore = computed(() => useCartStore().items.length)
  const { t } = useNuxtApp().$i18n
  const toast = useToast()
  if (isStoreHasPayWays) {
    if (!selectedPayWays.payType) {
      toast.error(t('choose_one_of_the_payment_methods_first'), {
        timeout: 1000
      })
      return
    }
    if (
      selectedPayWays.payType === t('pay_by_transfer') &&
      !selectedPayWays.payWayCard
    ) {
      toast.error(t('error_payWayCard'), { timeout: 1500 })
      return
    }
  }

  if (totalPrice > 0 || !priceVisible) {
    const whatsapp = useWhatsappNumer(
      table?.table_id !== undefined && table?.table_id !== null,
      store
    )
    if (whatsapp) {
      let lineAll = ''
      let lineItemsOffersText = ''
      let lineItemsText = ''

      if (itemsWithOutOffers.length) {
        lineItemsText = itemsWithOutOffers.reduce((total, item) => {
          let itemPrice = item.material.finalPrice

          if (item.material.offers?.length) {
            itemPrice = parseFloat(item.material.discountPrice!) || 0
          }
          let line =
            encodeURIComponent(
              getMaterialNameWithPrice(
                priceVisible,
                item,
                itemPrice,
                currency.code
              )
            ) + '%0A'
          if (store?.send_with_material_code) {
            line +=
              encodeURIComponent(
                `${t('material_code')}: ${item.material.code}`
              ) + '%0A'
          }
          if (store?.send_with_material_url) {
            line += encodeURIComponent(`${t('material_link')}:`) + '%0A'
            line +=
              encodeURIComponent(
                `${urlLocal + '/materials/' + item.material.id}`
              ) + '%0A'
          }
          if (item.properties_lookup_values?.length) {
            line +=
              encodeURIComponent(`        ${t('detailed_specifications')}:`) +
              '%0A'
            line += item.allDefinitionsLookupValues?.reduce(
              (total, definitionsLookupValues, index) => {
                let lineLocal = `           ${index + 1}:  ${
                  definitionsLookupValues?.label
                } : `

                definitionsLookupValues.propertiesListValues?.forEach(
                  (property, indexProperties) => {
                    if (indexProperties !== 0) {
                      lineLocal += '-'
                    }
                    if (definitionsLookupValues.type === 'Multi color') {
                      lineLocal += property?.option?.description ?? ''
                    } else {
                      lineLocal += property?.option?.value ?? ''
                    }
                  }
                )
                lineLocal = encodeURIComponent(lineLocal) + '%0A'
                return total + lineLocal
              },
              ''
            )
          }
          if (item.addons?.length) {
            line +=
              encodeURIComponent(`        ${t('paid_extra_attributes')}:`) +
              '%0A'
            line += item.addons?.reduce((total, attribut, index) => {
              const line =
                encodeURIComponent(
                  priceVisible
                    ? `           ${index + 1}:  ${attribut.name} - ${
                        attribut.desc || ''
                      } - ${attribut.pivot?.quantity ?? 0} × ${attribut.price}`
                    : `           ${index + 1}:  ${attribut.name} - ${
                        attribut.desc || ''
                      } - ${attribut.pivot?.quantity ?? 0}}`
                ) + '%0A'

              return total + line
            }, '')
          }
          if (
            item.addons?.length ||
            store?.send_with_material_code ||
            item.properties_lookup_values?.length ||
            store?.send_with_material_url
          ) {
            line += '%0A'
          }

          return total + line
        }, '')
        lineItemsText += encodeURIComponent('--------------') + '%0A'
      }

      if (allOffersWithItems.length) {
        lineItemsOffersText +=
          encodeURIComponent(`${t('offer_materials')} :`) + '%0A'
        allOffersWithItems.forEach((itemsPerOffer: CartItem[]) => {
          if (itemsPerOffer.length) {
            lineItemsOffersText +=
              /*   '%0A' + */
              encodeURIComponent(
                ` - ${t('offer_with', {
                  number: itemsPerOffer[0].material_group_offer!.offer_number
                })}`
              ) + '%0A'

            if (itemsPerOffer[0].material.store?.price_visible) {
              lineItemsOffersText +=
                encodeURIComponent(
                  t('offer_after_discount', {
                    price: itemsPerOffer[0].material_group_offer!.price
                  })
                ) + '%0A'
            }

            lineItemsOffersText +=
              encodeURIComponent(`   ${t('materials')}: `) +
              '%0A' +
              itemsPerOffer.reduce((total, item) => {
                let line =
                  encodeURIComponent(
                    item.material.store?.price_visible
                      ? `      * ${item.material.name}:         ${
                          item.quantity
                        } × ${useStringPrice(
                          item.material.finalPrice,
                          currency.code
                        )}`
                      : `      * ${item.material.name}:         ${item.quantity} `
                  ) + '%0A'
                if (store?.send_with_material_code) {
                  line +=
                    encodeURIComponent(
                      `         ${t('material_code')}: ${item.material.code}`
                    ) + '%0A'
                }
                if (store?.send_with_material_url) {
                  line +=
                    encodeURIComponent(`         ${t('material_link')}:`) +
                    '%0A'
                  line +=
                    encodeURIComponent(
                      `${urlLocal + '/product/' + item.material.id}`
                    ) + '%0A'
                }
                if (item.addons?.length) {
                  line +=
                    encodeURIComponent(`    ${t('paid_extra_attributes')}:`) +
                    '%0A'
                  line += item.addons?.reduce((total, itemLocal, index) => {
                    const line =
                      encodeURIComponent(
                        item.material.store?.price_visible
                          ? `             ${index + 1}:  ${itemLocal.name} - ${
                              itemLocal.desc || ''
                            } -  ${itemLocal.pivot?.quantity ?? 0} ×  ${
                              itemLocal.pivot?.quantity ?? 0
                            } `
                          : `             ${index + 1}:  ${itemLocal.name} - ${
                              itemLocal.desc || ''
                            } - ${itemLocal.pivot?.quantity ?? 0}`
                      ) + '%0A'

                    return total + line
                  }, '')
                }
                if (
                  item.addons?.length ||
                  store?.send_with_material_code ||
                  store?.send_with_material_url
                ) {
                  line += '%0A'
                }
                return total + line
              }, '')
          }
        })
        lineItemsOffersText += encodeURIComponent('--------------') + '%0A'
      }

      lineAll = lineItemsText + lineItemsOffersText

      if (table?.table_number) {
        lineAll +=
          encodeURIComponent(t('table_with', { number: table.table_number })) +
          '%0A'
      } else if ((delivery?.deliveryCost || 0) > 0) {
        lineAll += encodeURIComponent(`${t('shipping_details')}: `) + '%0A'
        lineAll += encodeURIComponent(`       ${delivery?.title}  `) + '%0A'
        if (delivery?.type === 'store_delivering_way') {
          lineAll +=
            encodeURIComponent(`       ${store.shipping_details}  `) + '%0A'
        }
        lineAll += encodeURIComponent('--------------') + '%0A'
      }
      if (store.price_visible) {
        lineAll += encodeURIComponent(
          `${t('initial_amount')}:  ${countCostItems.originalPrice.toFixed(
            2
          )} ${currency.code}`
        )
        if (countCostItems.discount > 0) {
          lineAll +=
            '%0A' +
            encodeURIComponent(
              `${t('discount')}:  ${useStringPrice(
                countCostItems.discount,
                currency.code
              )}`
            )
        }

        if ((delivery?.deliveryCost || 0) > 0 && !table?.table_number) {
          lineAll +=
            '%0A' +
            encodeURIComponent(
              `${t('shipping_cost')}:   ${useStringPrice(
                delivery!.deliveryCost || 0,
                currency.code
              )}`
            )
        }

        if (tablePriceAdde > 0 && table?.table_number) {
          lineAll +=
            '%0A' +
            encodeURIComponent(
              `${t('table_cost')}:   ${useStringPrice(
                tablePriceAdde || 0,
                currency.code
              )}`
            )
        }

        lineAll +=
          '%0A' +
          encodeURIComponent(
            `${t('total')}:  ${useStringPrice(totalPrice, currency.code)}`
          )
      }

      lineAll +=
        '%0A' +
        encodeURIComponent(`${t('total_number')}:  ${quantityItemsPerStore.value}`)

      if (notes) {
        lineAll += `%0A ${t('notes')}: ${notes}`
      }
      if (isStoreHasPayWays) {
        const payWayCardName = selectedPayWays?.payWayCard?.name
        lineAll += `%0A ${t('pay_way')}: ${selectedPayWays.payType} ${
          payWayCardName ? ': (' + payWayCardName + ')' : ''
        }`
        if (payWayCardName && selectedPayWays.payWayCard!.account_owner_name) {
          lineAll += `%0A ${t('account_owner_name')}: %20 ${
            selectedPayWays.payWayCard!.account_owner_name
          }`
          lineAll += `%0A ${t('iban_number')}: %20 ${
            selectedPayWays.payWayCard!.iban
          }`
        }
      }

      window.open(
        'https://api.whatsapp.com/send?phone=' + whatsapp + '&text=' + lineAll,
        '_blank'
      )
      if (openDialog) {
        openDialog(store)
      }
    } else {
      toast.error(
        t(
          table?.table_number
            ? 'store_does_not_have_lounge_phone'
            : 'store_does_not_have_phone'
        ),
        { timeout: 1000 }
      )
    }
  } else {
    toast.error(t('no_products_to_purchase'), { timeout: 1000 })
  }
}
